.footer {
  background-color: var(--background);
  padding: 4rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.footer .logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-primary);
}

.footer-links {
  display: flex;
  gap: 2rem;
}

.footer-links a {
  color: var(--text-secondary);
  text-decoration: none;
  transition: color 0.2s ease;
}

.footer-links a:hover {
  color: var(--primary-color);
}

.copyright {
  text-align: center;
  color: var(--text-secondary);
  margin: 0;
  font-size: 0.875rem;
} 