.legal-page {
  padding: 8rem 0;
  background-color: var(--background);
}

.legal-page .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.legal-page .content-wrapper {
  max-width: 800px;
}

.legal-page h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.legal-page .last-updated {
  color: var(--text-secondary);
  margin-bottom: 2rem;
  font-size: 1rem;
}

.legal-page section {
  margin-bottom: 1.5rem;
}

.legal-page h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.legal-page h3 {
  font-size: 1.5rem;
  margin: 1rem 0 0.75rem;
  color: var(--text-primary);
}

.legal-page p {
  color: var(--text-secondary);
  font-size: 1.125rem;
  line-height: 1.6;
  margin-bottom: 0.75rem;
}

.legal-page ul {
  list-style-type: disc;
  margin: 0.75rem 0 1rem 1.5rem;
  color: var(--text-secondary);
}

.legal-page li {
  font-size: 1.125rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
} 