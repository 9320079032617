.cta {
  background-color: var(--background);
  padding: 8rem 0;
}

.cta .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.cta .content-wrapper {
  max-width: 800px;
}

.cta h2 {
  font-size: 3.5rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  color: var(--text-primary);
}

.cta p {
  font-size: 1.25rem;
  margin-bottom: 2.5rem;
  color: var(--text-secondary);
  line-height: 1.6;
}

.cta .cta-button {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 1.25rem 3rem;
  background-color: var(--primary-color);
  border-radius: 3rem;
  text-decoration: none;
  display: inline-block;
}

.cta .cta-button:hover {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
  text-decoration: none;
} 