.hero {
  padding: 12rem 0 8rem;
  background: linear-gradient(150deg, #7b61ff 0%, #a891ff 50%, #e33cd4 100%);
  position: relative;
  overflow: hidden;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.1) 0%, transparent 70%);
  pointer-events: none;
}

.hero .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.hero .content-wrapper {
  max-width: 800px;
}

.hero h1 {
  font-size: 5.5rem;
  line-height: 1;
  color: white;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
}

.hero .subtitle {
  font-size: 1.5rem;
  max-width: 600px;
  margin: 0 0 3rem 0;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
  position: relative;
  z-index: 1;
}

.hero .cta-button {
  background: white;
  color: var(--primary-color);
  font-size: 1.25rem;
  font-weight: 600;
  padding: 1.25rem 3rem;
  border-radius: 3rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  position: relative;
  z-index: 1;
  text-decoration: none;
  display: inline-block;
}

.hero .cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background: white;
  text-decoration: none;
}

.hero-image {
  margin-top: 4rem;
  max-width: 1000px;
  margin: 4rem auto 0;
}

.hero-image img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
} 