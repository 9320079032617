.contact-page {
  min-height: 100vh;
  background: linear-gradient(150deg, #7b61ff 0%, #a891ff 50%, #e33cd4 100%);
  padding: 4rem 2rem;
  font-family: var(--font-family);
}

.split-container {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: start;
}

.info-section {
  color: white;
  padding-top: 4rem;
}

.info-section h1 {
  font-size: 4.5rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.04em;
}

.info-section .subtitle {
  font-size: 1.25rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  max-width: 600px;
}

.form-section {
  background: white;
  border-radius: 1rem;
  padding: 3rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.form-header {
  margin-bottom: 2.5rem;
}

.form-header h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
  font-weight: 600;
  letter-spacing: -0.02em;
}

.form-header p {
  font-size: 1.125rem;
  color: var(--text-secondary);
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.form-field {
  display: flex;
  flex-direction: column;
}

.form-field.full-width {
  grid-column: span 2;
}

.form-field label {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.form-field input,
.form-field select,
.form-field textarea {
  padding: 0.875rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  font-size: 1rem;
  font-family: var(--font-family);
  color: var(--text-primary);
  background: white;
  transition: all 0.2s ease;
  width: 100%;
}

.form-field input::placeholder,
.form-field textarea::placeholder {
  color: var(--text-secondary);
  opacity: 0.7;
}

.form-field select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  padding-right: 2.5rem;
}

.form-field input:focus,
.form-field select:focus,
.form-field textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 1px var(--primary-color);
}

.form-field textarea {
  resize: vertical;
  min-height: 100px;
}

.field-note {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin-top: 0.5rem;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2.5rem 0;
}

.button-primary,
.button-secondary {
  padding: 0.875rem 2rem;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--font-family);
  cursor: pointer;
  transition: all 0.2s ease;
}

.button-primary {
  background: var(--primary-color);
  color: white;
  border: none;
}

.button-primary:hover {
  background: var(--primary-hover);
  transform: translateY(-1px);
}

.button-secondary {
  background: transparent;
  color: var(--text-primary);
  border: none;
}

.button-secondary:hover {
  color: var(--primary-color);
}

.privacy-notice {
  font-size: 0.875rem;
  color: var(--text-secondary);
  text-align: center;
}

.privacy-notice a {
  color: var(--primary-color);
  text-decoration: none;
}

.privacy-notice a:hover {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .split-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .info-section {
    padding-top: 0;
  }

  .info-section h1 {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .contact-page {
    padding: 2rem 1rem;
  }

  .form-section {
    padding: 2rem;
  }

  .form-grid {
    grid-template-columns: 1fr;
  }

  .form-field.full-width {
    grid-column: span 1;
  }
} 