.how-it-works {
  background-color: var(--background-alt);
}

.how-it-works h2 {
  max-width: 800px;
}

.steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  margin-top: 4rem;
}

.step {
  position: relative;
  text-align: left;
}

.step-number {
  width: 3rem;
  height: 3rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.step h3 {
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.step p {
  color: var(--text-secondary);
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.6;
} 