.navbar {
  padding: 1.5rem 0;
  background-color: var(--background);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-primary);
}

.navbar .cta-button {
  padding: 0.75rem 1.5rem;
} 