:root {
  --primary-color: #635bff;
  --primary-hover: #5851ea;
  --text-primary: #0a2540;
  --text-secondary: #425466;
  --background: #ffffff;
  --background-alt: #f6f9fc;
  --font-family: -apple-system, BlinkMacSystemFont, 'Sohne', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  color: var(--text-primary);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

h1, h2, h3 {
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: -0.04em;
}

h1 {
  font-size: 4.5rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

h2 {
  font-size: 2.75rem;
  margin-bottom: 2rem;
  text-align: left;
}

h3 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  text-align: left;
}

p {
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  line-height: 1.6;
  text-align: left;
}

.cta-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 2rem;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: var(--font-family);
  text-decoration: none;
  display: inline-block;
}

.cta-button:hover {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
  text-decoration: none;
}

section {
  padding: 6rem 0;
} 