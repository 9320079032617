.features {
  background-color: var(--background);
}

.features .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.features .content-wrapper {
  max-width: 800px;
}

.features h2 {
  max-width: 800px;
  margin-bottom: 4rem;
  color: var(--text-primary);
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.feature-card {
  padding: 2rem 0;
  background: var(--background);
  transition: transform 0.2s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

.feature-card h3 {
  margin-bottom: 1rem;
  color: var(--text-primary);
  font-size: 1.75rem;
}

.feature-card p {
  color: var(--text-secondary);
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.6;
  max-width: 90%;
} 